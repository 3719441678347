import React from 'react';
import { bool } from 'prop-types';

import LongFilterPlain from './LongFilterPlain';
import LongFilterPopup from './LongFilterPopup';

const LongFilter = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return showAsPopup ? (
    <LongFilterPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <LongFilterPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );
};

LongFilter.defaultProps = {
  showAsPopup: false,
};

LongFilter.propTypes = {
  showAsPopup: bool,
};

export default LongFilter;
